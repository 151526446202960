import { TelegramPost } from '@shared/master-types';
import { useMemo } from 'react';

import { useGetTelegramPostsQuery } from '../features/telegramPosts/getTelegramPosts.query';

export type TUseTelegramPostsProps = {
  perPage: number;
  page: number;
  excludePostID?: string;
};

export type TUseTelegramPostsResult = {
  isLoading: boolean;
  docsArray: TelegramPost[];
  totalPages?: number;
};

const useTelegramPosts = ({
  perPage,
  page,
  excludePostID,
}: TUseTelegramPostsProps): TUseTelegramPostsResult => {
  const numberPerPage = Number(perPage);

  const telegramPostsQuery = useMemo(() => {
    return {
      draft: false,
      page: page,
      limit: numberPerPage,
      where: {
        isHidePost: { equals: false },
        isBroken: { equals: false },
        ...(excludePostID ? { id: { not_equals: excludePostID } } : {}),
      },
    };
  }, [page, numberPerPage, excludePostID]);

  const { data: telegramPostsResponse, isLoading } =
    useGetTelegramPostsQuery(telegramPostsQuery);

  const docsArray = telegramPostsResponse?.docs || [];

  return {
    isLoading,
    docsArray,
  };
};

export default useTelegramPosts;
